@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body{
background-image: url('../../../public/assets/img/mainbg.jpg');
height: 100vh;
background-position-y: bottom;
font-family: "Poppins", sans-serif;
font-weight: 400;
font-style: normal;
background-repeat: no-repeat;
background-size: contain;}

.comfortaa{
    font-family: "Comfortaa", sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
  }
.headerbg{
    background-color: #1B3275;
    padding: 30px;
    height: 194px;
    h4{
color: #fff;
font-size: 19px;
    }
}
.whitebox{
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.12);
    border-radius: 5px;
    margin-top: 15px;
    padding: 15px;
    padding-bottom: 10px;
    h5{
        font-size: 16px;
    }
    p{
        font-size: 13px;
    }
}
.container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 750px;
}
.font-12{
    font-size: 12px;
}
hr{
    margin: 0px;
}
label{
    font-size: 14px;
    margin-bottom: 0px!important;
}
select, input{
    font-size: 14px !important;
    height: 45px;
    border: 1px solid #DDDDDD;
}
.greencolor{
    color: #09C771;
}

.bodywhite{
    min-height: 867px;
    background: white;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.12);
    padding: 0px;
    position: relative;
}

.kw{
    .btn{
        font-size: 13px;
        font-weight: 600;
    }
}

.btn-main{
    width: 200px;
    font-size: 16px;
    background-color: #09C771;
    color: #fff;
    border:1px solid #09C771;
    &:hover{
        background-color: #0c8f53;
        color: #fff;
    }
}

.btn-main-outline{
    width: 200px;
    font-size: 16px;
    border: 1px solid #09C771;
    background: white;
    color: #09C771;
    &:hover{
        background-color: #0c8f53;
        color: #fff;
    }
}
.footerimg{
    position: absolute;
    width: 100%;
    margin-left: -30px;
    bottom: 12px;
}
@media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 480px)
  and (-webkit-min-device-pixel-ratio: 2) {
    .btn-main{
        width: 49%!important;
    }
    .headerbg {
        padding: 16px;
        padding-top:25px;
    }
    .btn-main-outline{
        width: 49%!important;
    }
}

.font-14{
    font-size: 14px!important;
}

.note {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    display: block;
}
.btn:disabled, .btn.disabled, fieldset:disabled .btn{
    background-color: #AFAFAF;
}

.kw{
    .active{
        background-color: #F9A885;
        border:1px solid #F9A885 ;
        color: #191919;
    }
   
}
a{
    text-decoration: none;
}
.w-inhariat{
    width: inherit;
}

.greencolor{
    color: #09C771;
}


.cursor-pointer{
    cursor: pointer;
}
.paymentbox .whitebox.cursor-pointer{
    border:1px solid #fff;
}
.paymentbox .whitebox.cursor-pointer:hover{
    border:1px solid #09C771;
}
.activeborder{
    border:1px solid #09C771!important;
}
.successmodal {
    margin-top: -25px;
}
.smallselect{
        height: 30px;
        padding-left: 7px;
        padding-right: 3px;
        border-radius: 3px;
}

.gradient {
    min-height: 867px;
    background: linear-gradient(180deg, #FFF1EA 0%, #FFFFFF 100%);
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.12);
    padding: 0px;
    position: relative;
}
.instuctionetext p{
    color: #006185;
    font-size: 16px;
}

.font-16{
    font-size: 16px!important;
}

.startcircle{
    background: radial-gradient(50% 50% at 50% 50%, #59E890 0%, #2DD99F 100%);
border: 1px solid #FFFFFF;
box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.12);
width: 100px;
height: 100px;
margin: 0 auto;
border-radius: 50%;
align-content: center;
font-size: 14px;
font-weight: 700;
color: #fff;

}

.stopcircle{
    background: radial-gradient(50% 50% at 50% 50%, #EE4142 0%, #E61515 100%);
border: 1px solid #FFFFFF;
box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.12);
width: 100px;
height: 100px;
margin: 0 auto;
border-radius: 50%;
align-content: center;
font-size: 14px;
font-weight: 700;
color: #fff;

}
.bluebackground{
    background-color: #5656E8;
}
.battarybg{
    background-image: url('../../../public/assets/img/battary.svg');
    background-repeat: no-repeat;
    height: 122px;
    text-align: center;
    background-position-x: center;
    background-size: cover;
    padding-top: 17px;
    margin-top: -16px;
}
.progress{
    height: 84px;
    margin-top: 0px;
    margin-left: 22px;
    margin-right: 22px;
}
.progress .progress-bar{
    font-size: 16px!important;
    font-weight: 900!important;
    background-color: #09C771!important;
}

.minheight{
    min-height: 520px;
}

.offcanvas.offcanvas-bottom{
    background: linear-gradient(180deg, #FFEDED 0%, #FFFFFF 100%);
border-radius: 20px 20px 0px 0px;
max-width: 752px;
margin: 0 auto;
}

.offcamvnas-height{
    height:  38vh!important;
}
.margintopminus{
    margin-top: -20px;
}